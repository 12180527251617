html {
  scroll-snap-type: y mandatory;
}


.app {
  background-color: black;
  display: grid;
  place-items: center;
}
video {
  object-fit: cover !important;
}

.vjs-poster {
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    width: 100vw!important;
    height: 100vh !important;
    background-size: cover !important;
    vertical-align: top !important;
    justify-content: left !important;
    background-color: transparent !important;
    object-fit: cover !important;
}

.videosContainer {
  position: relative;
  width: 100vw;
  overflow: scroll;
  scroll-snap-type: y mandatory;
}
.videosContainer::-webkit-scrollbar {
  display: none;
}
.videosContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.verticalSlider{
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
}
.fontTest{
  font-family: 'TextaAltBold';
  font-size: 52px;
}
.swiper{
  background-color: black;
}