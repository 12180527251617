.modalContainer {
  position: absolute;
  height: auto;
  min-height: 14vh;
  width: 87vw;
  top: 72%;
  border-radius: 15px;
  background-color: #E8F8DC;
  border: 4px solid white;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  z-index: 3000;
}
.modalSection{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4000;

}
.outline {
    width: 44px;
    height: 46px;
    float: right;
    clear:right;
    margin-top: -10px;
    margin-right: -5px;
  }
.modalText {
  width: 100%;
  font-size: 19.5px;
  color: black;
  text-align: center;
  font-family: 'TextaAltBlack';
  line-height: 1.1;
}

@media (min-width: 768px)  {

  .modalContainer {
    position: absolute;
    height: auto;
    min-height: 14vh;
    width: 93vw;
    top: 71.5%;
    border-radius: 15px;
    background-color: #E8F8DC;
    border: 4px solid white;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    z-index: 3000;
  }


  .outline {
      width: 71px;
      height: 71px;
      float: right;
      clear:right;
      margin-top: -50px;
      margin-right: 3px;
    }

    .modalText {
      width: 100%;
      font-size: 25.5px;
      color: black;
      text-align: center;
      font-family: 'TextaAltBlack';
      line-height: 1.1;
    }

}
