.volumeButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 15%;
  height: 8.9%;
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  z-index: 9999;
  min-width: 60px;
  min-height: 60px;
}
.volumeImage{
  width: 50%;
  min-width: 30px;
  height: auto;
}