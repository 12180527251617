.videoSidebar {
    position: absolute;
    top: 50%;
    right: 1%;
    height: 33.7%;
    color: white;
    display: grid;
    align-items: center;
    justify-content: center;
    align-content: center;
    z-index: 5000;
    width: auto;
}
.videoSidebarButton {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.seriesLink {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.profileImage {
  height: auto;
  width: 55px;
  border-radius: 50%;
  border-style: solid;
  border-width: 3px;
  border-color: white;
  filter: drop-shadow(0px 0px 2px #808080);

}
.heartImage {
  width: 45px;
  height: auto;
  filter: drop-shadow(0px 0px 2px #808080);
}
.cloudImage{
  width: 45px;
  height: auto;
  z-index: 2000;
  filter: drop-shadow(0px 0px 2px #808080);
}
.cloudImageClose{
  width: 45px;
  height: auto;
  z-index: 2000;
  filter: drop-shadow(0px 0px 2px #808080);
}
.play {
  bottom: -13%;
  position: absolute;
  width: 35%;
}

@media (min-width: 768px)  {

  .profileImage {
    height: auto;
    width: 90px;
    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    border-color: white;
    filter: drop-shadow(3px 5px 3px #808080);


  }
  .heartImage {
    width: 71px;
    height: auto;
    filter: drop-shadow(3px 5px 3px #808080);
  }
  .cloudImage{
    width: 71px;
    height: auto;
    z-index: 2000;
    filter: drop-shadow(3px 5px 3px #808080);
  }


 }
