@font-face {
  font-family: 'TextaAltBlack';
  src: local('TextaAltBlack'), url('./fonts/Texta_Alt-Black.otf') format('opentype');
}


@font-face {
  font-family: 'TextaAltBold';
  src: local('TextaAltBold'), url('./fonts/Texta_Alt-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'TextaAltBook';
  src: local('TextaAltBook'), url('./fonts/Texta_Alt-Book.otf') format('opentype');
}

@font-face {
  font-family: 'TextaAlt';
  src: local('TextaAlt'), url('./fonts/Texta_Alt-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'TextaAltThin';
  src: local('TextaAltThin'), url('./fonts/Texta_Alt-Thin.otf') format('opentype');
}

* {
  margin: 0;
}
body {
  margin: 0;
  font-family: 'TextaAltBold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
