.endScreen{
  position: absolute;
  height: auto;  
  width: 100%;
  overflow-y: hidden;
;
}

.endButton{ 
background-color: transparent;
position: absolute;
width: 66%;
height: 37.5%;
bottom: 23.4%;
left: 0; 
right: 0; 
margin-left: auto; 
margin-right: auto; 
}