.logoContainerTop {
  position: absolute;
  top: 2%;
  left: 2.5%;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  height: auto;
  width: auto;
}

.logoContainerBottom {
  position: absolute;
  bottom: 2.5%;
  right: 2.5%;
  display: flex;
  flex-direction: column;
  z-index: 100;
  -webkit-filter: blur(.4px);
  -moz-filter: blur(.4px);
  -o-filter: blur(.4px);
  -ms-filter: blur(.4px);

}

.logoSection {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;

}


.tiktokLogoContainerTop {
  display: flex;
  flex-direction: row;
  margin-left: 3px;
}

.tiktokLogoContainerBot {
  display: flex;
  flex-direction: row;
  margin-right: 3px;
  height: 60%;
}

.sensicaltv {
  width: auto;
  margin-right: auto;
  -webkit-filter: blur(.1px);
  -moz-filter: blur(.1px);
  -o-filter: blur(.1px);
  -ms-filter: blur(.1px);
  margin-top: .4vh;
}

.wordLogo {
  margin-top: 7px;
  margin-left: 3px;
  -webkit-filter: blur(.3px);
  -moz-filter: blur(.3px);
  -o-filter: blur(.3px);
  -ms-filter: blur(.3px);
  filter: drop-shadow(0px 0px 2px #808080);

}
.sensicaltvbot{
  margin-left: auto;
  -webkit-filter: blur(.3px);
  -moz-filter: blur(.3px);
  -o-filter: blur(.3px);
  -ms-filter: blur(.3px);
}
.blended {
  height: 100%;
  -webkit-filter: blur(.3px);
  -moz-filter: blur(.3px);
  -o-filter: blur(.3px);
  -ms-filter: blur(.3px);
  filter: drop-shadow(0px 0px 2px #808080);
}
.hide {
  visibility: hidden;
  display: none;
}


