.videoPlayButton {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
}
.videoPlayButton > .MuiSvgIcon-root {
    font-size: 5rem !important;
    cursor: pointer;
}