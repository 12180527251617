.vidContainer {
    position: relative;
    background-color: white;
    width: 100vw;
    scroll-snap-align: start;
    max-width: 576px;
    align-items: center;

    
}
.videoPlayer {
    width: 100vw;
    object-fit: fill;
    overflow: hidden;
    display: block;
    min-height: -webkit-fill-available;
    align-items: center;
    
}
.cover{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1999;
  object-fit: cover !important;
}



.bigPlayButton{
  height: 55px;
  width: 55px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  pointer-events: none;
}